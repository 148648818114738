@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar {
  overflow-y: auto;
}
.scrollbar::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
  background-color: rgba(70, 70, 70, 0.103);
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #b9b9b9;
}

/* Main Menu */
.main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.main-menu > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.main-menu > ul > li + li {
  margin-left: 55px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .main-menu > ul > li + li {
    margin-left: 45px;
  }
}
.main-menu > ul > li:hover > a > span:before {
  left: 0;
  right: auto;
  width: 100%;
}
.main-menu > ul > li > a {
  line-height: 100px;
  text-decoration: none;
  letter-spacing: 0.5px;
}
.main-menu > ul > li > a:hover,
.main-menu > ul > li > a.active {
  color: #00937d;
}
.main-menu > ul > li > a:hover > span:before,
.main-menu > ul > li > a.active > span:before {
  left: 0;
  right: auto;
  width: 100%;
}
.main-menu > ul > li > a > span {
  position: relative;
}
.main-menu > ul > li > a > span:before {
  background-color: #00937d;
  content: "";
  position: absolute;
  left: auto;
  right: 0;
  bottom: -2px;
  height: 2px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 0;
}
.main-menu ul .has-children {
  position: relative;
}
.main-menu ul .has-children .sub-menu {
  position: absolute;
  background: #fff;
  width: 230px;
  padding: 20px 20px 24px;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 9;
  border-bottom: 3px solid #00937d;
  height: 500px;
  overflow: auto;
}
.main-menu ul .has-children .sub-menu li {
  list-style: none;
}
.main-menu ul .has-children .sub-menu li a {
  transition: all 0.2s ease-in-out;
  line-height: 40px;
  text-decoration: none;
}
.main-menu ul .has-children .sub-menu li a:hover {
  color: #00937d;
  padding-left: 5px;
}
.main-menu ul .has-children:hover .sub-menu,
.main-menu ul .has-children:hover .mega-menu {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

/* Mega Menu */

/* Product Tab Nav */
.product-tab-nav .nav-item {
  margin-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .product-tab-nav .nav-item {
    margin-right: 20px;
  }
}
.product-tab-nav .nav-item:last-child {
  margin-right: 0;
}
.product-tab-nav .nav-item .nav-link {
  line-height: 1;
  display: block;
  padding: 0 0 15px 0;
  position: relative;
}
.product-tab-nav .nav-item .nav-link:after {
  position: absolute;
  content: "";
  color: #00937d;
  background: #00937d;
  width: 0%;
  height: 2px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  top: 100%;
  left: 0;
}
.product-tab-nav .nav-item .nav-link:hover,
.product-tab-nav .nav-item .nav-link.active {
  color: #00937d;
}
.product-tab-nav .nav-item .nav-link:hover:after,
.product-tab-nav .nav-item .nav-link.active:after {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .product-tab-nav .nav-item .nav-link {
    font-size: 16px;
    padding: 0 0 10px 0;
  }
}
.title-border-bottom {
  border-bottom: 2px solid #ebebeb;
}
/* Product Tab Nav */

/*-- Shop Sidebar Area Start --*/
.sidebar_widget .widget_inner .widget-title {
  font-size: 20px;
  line-height: 1;
  position: relative;
  padding-bottom: 10px;
}
.sidebar_widget .widget_inner .widget-title:after {
  background-color: #ebebeb;
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
}
@media only screen and (max-width: 575px) {
  .sidebar_widget .widget_inner .widget-title {
    font-size: 18px;
  }
}
.sidebar_widget .widget_inner .search-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.sidebar_widget .widget_inner .search-box .form-control {
  border: 1px solid #e1e1e1;
  padding: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: block;
  border-radius: 0;
  height: 45px;
  font-size: 14px;
  width: calc(100% - 40px);
}
.sidebar_widget .widget_inner .search-box .form-control:focus {
  border-color: #00937d;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sidebar_widget .widget_inner .search-box .btn {
  border-radius: 0;
  color: #fff;
  height: 45px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px;
}
.sidebar_widget .widget_inner .search-box .btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sidebar_widget .widget_inner .widget-list .category-menu {
  overflow: inherit;
}
.sidebar_widget .widget_inner .widget-list .category-menu > li {
  line-height: 1;
}
.sidebar_widget .widget_inner .widget-list .category-menu > li > a {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.sidebar_widget .widget_inner .widget-list .category-menu > li > a i {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.sidebar_widget .widget_inner .widget-list .category-menu > li > a:hover {
  color: #00937d;
}
.sidebar_widget .widget_inner .widget-list .category-menu > li.active > a i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.sidebar_widget .widget_inner .widget-list .category-menu > li > .dropdown {
  padding-left: 10px;
}
.sidebar_widget
  .widget_inner
  .widget-list
  .category-menu
  > li
  > .dropdown
  li
  a {
  font-size: 13px;
  padding-top: 15px;
  display: block;
}
.sidebar_widget .widget_inner .widget-list .slider-range-submit {
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  text-transform: capitalize;
  color: #fff;
  border: 0;
  border-radius: 30px;
  font-size: 14px;
}
.sidebar_widget .widget_inner .widget-list .slider-range-submit:focus {
  outline: 0px;
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
}
.sidebar_widget .widget_inner .widget-list .slider-range-submit:hover {
  background: #00937d;
}
.sidebar_widget .widget_inner .widget-list .slider-range-amount {
  background: none;
  border: none;
  font-size: 12px;
  text-align: center;
  width: 150px;
  border-radius: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar_widget .widget_inner .widget-list .slider-range-amount {
    width: 130px;
  }
}
.sidebar_widget .widget_inner .widget-list .slider-range-amount:focus {
  border: 1px solid #00937d;
  border-radius: 30px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sidebar_widget .widget_inner .widget-list .sidebar-body .tags li {
  display: inline-block;
}
.sidebar_widget
  .widget_inner
  .widget-list
  .sidebar-body
  .checkbox-container
  li {
  padding: 10px 0;
  line-height: 1;
}
.sidebar_widget
  .widget_inner
  .widget-list
  .sidebar-body
  .checkbox-container
  li:last-child {
  padding-bottom: 0px;
}
.sidebar_widget
  .widget_inner
  .widget-list
  .sidebar-body
  .checkbox-container
  .custom-control-input {
  margin-right: 10px;
  cursor: pointer;
}
.sidebar_widget
  .widget_inner
  .widget-list
  .sidebar-body
  .checkbox-container
  .custom-control-label {
  font-size: 14px;
  text-transform: capitalize;
  line-height: 1;
  cursor: pointer;
}
.sidebar_widget
  .widget_inner
  .widget-list
  .sidebar-body
  .single-product-list
  .thumb {
  width: 60px;
  height: 100%;
}
.sidebar_widget
  .widget_inner
  .widget-list
  .sidebar-body
  .single-product-list
  .thumb
  img {
  width: 100%;
  height: auto;
}
.sidebar_widget
  .widget_inner
  .widget-list
  .sidebar-body
  .single-product-list
  .content {
  width: calc(100% - 60px);
  padding-left: 15px;
}
.sidebar_widget
  .widget_inner
  .widget-list
  .sidebar-body
  .single-product-list
  .content
  .title {
  font-size: 14px;
}

.sidebar-list > li > a {
  font-size: 14px;
  text-transform: capitalize;
  line-height: 1;
  position: relative;
  display: block;
  padding: 10px 0;
}
.sidebar-list > li > a:hover {
  color: #00937d;
}
.sidebar-list > li:last-child a {
  padding-bottom: 0;
}
.sidebar-list .btn {
  height: 20px;
  max-width: 90px;
  min-width: 20px;
  position: relative;
  border: 1px solid #ebebeb;
  display: inline-block;
  margin: 0 0px 0px 20px;
  border-radius: 0px;
}
.sidebar-list .btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #00937d;
  outline: 0;
}

.tags li a {
  border: 1px solid #ebebeb;
  line-height: 1;
  padding: 10px 15px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 25px;
  font-size: 14px;
}
.tags li a:hover {
  color: #fff;
  background: #00937d;
  border-color: #00937d;
}
/*-- Shop Sidebar Area Start --*/
/*-- Shop Toolbar Area Start Here --*/
.shop_toolbar_wrapper {
  padding: 10px 15px;
  border: 1px solid #ebebeb;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 479px) {
  .shop_toolbar_wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.shop_toolbar_wrapper .shop_toolbar_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 479px) {
  .shop_toolbar_wrapper .shop_toolbar_btn {
    margin-bottom: 10px;
  }
}
.shop_toolbar_wrapper .shop_toolbar_btn button {
  border: 1px solid #ebebeb;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 10px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding: 6px 5px 4px;
  background-color: transparent;
}
.shop_toolbar_wrapper .shop_toolbar_btn button.active {
  border: 1px solid #00937d;
  color: #00937d;
}
.shop_toolbar_wrapper .shop_toolbar_btn button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.shop_toolbar_wrapper .shop_toolbar_btn button:hover {
  color: #00937d;
  border-color: #00937d;
}
.shop_toolbar_wrapper .shop_toolbar_btn button:last-child {
  margin-right: 0px;
}
.shop_toolbar_wrapper .shop-top-bar-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.shop_toolbar_wrapper .shop-top-bar-right .shop-short-by {
  margin-right: 20px;
}
.shop_toolbar_wrapper .shop-top-bar-right .shop-short-by .nice-select {
  border-radius: 0px;
  border-color: #ebebeb;
  height: 32px;
  line-height: 30px;
}
.shop_toolbar_wrapper .shop-top-bar-right .shop-short-by .nice-select .list {
  width: 160px;
  padding: 10px 0;
  border-radius: 0px;
  z-index: 99;
}
.shop_toolbar_wrapper .shop-top-bar-right .shop-short-by .nice-select li {
  margin: 0 10px;
  padding: 10px 0;
  background: transparent;
  line-height: 1;
  min-height: 20px;
}
.shop_toolbar_wrapper .shop-top-bar-right .shop-short-by .nice-select li:hover {
  padding-left: 5px;
  display: block;
  color: #00937d;
}
.shop_toolbar_wrapper .shop-top-bar-right .shop-short-by .nice-select:focus {
  border-color: #00937d;
}
.shop_toolbar_wrapper .shop-top-bar-right .shop-short-by .nice-select .current {
  color: #212121;
  font-size: 14px;
}
@media only screen and (max-width: 479px) {
  .shop_toolbar_wrapper .shop-top-bar-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .shop_toolbar_wrapper .shop-top-bar-right .shop-short-by {
    margin-right: 0px;
    margin-bottom: 15px;
  }
}
.shop_toolbar_wrapper .shop-top-bar-left .shop-top-show span {
  color: #212121;
  font-size: 14px;
}

/*-- Shop Toolbar Area End Here --*/

.swiper-slide-visible {
  opacity: 0.4 !important;
}
.swiper-slide-thumb-active {
  opacity: 1 !important;
}

/* --- Quantity --- */
.quantity {
  width: 150px;
}
.quantity .cart-plus-minus {
  position: relative;
  width: 150px;
  text-align: left;
  height: 45px;
  overflow: hidden;
}
.quantity .cart-plus-minus > .cart-plus-minus-box {
  border: 1px solid #e8e8e8;
  height: 45px;
  text-align: center;
  width: 150px;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
}
.quantity .cart-plus-minus > .qtybutton {
  cursor: pointer;
  position: absolute;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  width: 35px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  color: #212121;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.quantity .cart-plus-minus > .qtybutton:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: #00937d;
  color: #fff;
}
.quantity .cart-plus-minus > .qtybutton.dec {
  top: 50%;
  left: 0%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid #e8e8e8;
}
.quantity .cart-plus-minus > .qtybutton.inc {
  top: 50%;
  right: 0%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  border-left: 1px solid #e8e8e8;
}

.group-quantity-product table tbody .quantity {
  min-width: 150px;
}
.group-quantity-product table tbody .pro-title,
.group-quantity-product table tbody .pro-price {
  vertical-align: middle;
  font-size: 14px;
  text-align: center;
  min-width: 150px;
  color: #212121;
}

/* Section TItle */
.section-title {
  margin-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .section-title {
    margin-bottom: 40px;
  }
}
.section-title .title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0;
  color: #2e2e2e;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 24px;
  }
}
.section-title span {
  content: "";
  position: absolute;
  background: #00937d;
  width: 100px;
  height: 2px;
}
/*-- Cart Table Style --*/
.cart-table .table {
  margin: 0;
  white-space: nowrap;
}
.cart-table .table thead tr th {
  border-color: #e8e8e8;
  border-bottom: 0 solid transparent;
  color: #212121;
  font-size: 15px;
  font-weight: 700;
  padding: 15px 20px;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table .table thead tr th {
    min-width: 180px;
  }
}
.cart-table .table tr {
  border-color: #e8e8e8;
}
.cart-table .table tr .pro-thumbnail,
.cart-table .table tr .pro-price,
.cart-table .table tr .pro-quantity,
.cart-table .table tr .pro-subtotal,
.cart-table .table tr .pro-remove {
  min-width: 177px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cart-table .table tbody td {
  border-color: #e8e8e8;
  text-align: center;
  padding: 10px 20px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 14px;
}
.cart-table .table tbody td a {
  color: #212121;
  font-weight: 500;
  text-transform: capitalize;
}
.cart-table .table tbody td a:hover {
  color: #00937d;
}
.cart-table .table tbody td a.btn {
  color: #fff;
}
.cart-table .table tbody td a.check-btn {
  color: #fff;
}
.cart-table .table tbody td.pro-remove a i {
  font-size: 24px;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ffdada;
  --bs-table-border-color: #e6c4c4;
  --bs-table-striped-bg: #f2cfcf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c4c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eccaca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #e8e8e8;
  --bs-table-border-color: #d1d1d1;
  --bs-table-striped-bg: gainsboro;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d1d1d1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d7d7d7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212121;
  --bs-table-border-color: #373737;
  --bs-table-striped-bg: #2c2c2c;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373737;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323232;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.ant-image {
  width: 100% !important;
  height: 100% !important;
}
.ant-image-img {
  width: 100% !important;
  height: 100% !important;
}
